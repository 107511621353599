import React from "react";
import Zoom from 'react-reveal/Zoom';

import CarouselCategory from './CarouselCategory'
import useWindowDimensions from '../hooks/useWindowDimensions'
import CarouselCategoryMin from './CarouselCategoryMin'

const Hero = props => {
  const { width } = useWindowDimensions();
  
  if (width <= 768) {
    return (
      <div className="home-hero">
        <Zoom>
          <div className='carousel-container'>
            <CarouselCategoryMin/>
          </div>
        </Zoom>
      </div>
    );
  }

  return (
    <div className="home-hero">
      <Zoom>
        <div className='carousel-container'>
          <CarouselCategory/>
        </div>
      </Zoom>
    </div>
  );
};

export default Hero;
