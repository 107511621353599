import React from "react";

import ImgEnvio from '../../assets/images/home/icon_envios.svg'
import Fade from 'react-reveal/Fade';

const Envio = props => {
  const { title, content } = props.data;
  return (
    <div className="home-envio">
      <Fade left><img src={ImgEnvio} alt="envio" height="180" width="180"/></Fade>
      <div className="envio-content">
        <h2> {title} </h2>
        <p> {content} </p>
      </div>
    </div>
  );
};

export default Envio;
