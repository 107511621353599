import React from "react";
import { Link } from "react-router-dom";

import Button from "../extras/Button";

const CategoriaSoporte = props => {
  const { title, description} = props.data;
  return (
    <div className='home-hacemos'>
      <div className="home-hacemos-list">
        <h2> {title} </h2>
        <p> {description} </p>
        <Link className="btn-category" to="/contacto">
          <Button title="MÁS INFORMACIÓN" />
        </Link>
      </div>
    </div>
  );
};

export default CategoriaSoporte;
