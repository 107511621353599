import React from 'react';

function Iframe(props) {
    return (
        <div className='embed-container'>
            <iframe
                title="pdf"
                className='embed-iframe'
                src={props.src}
                width={props.width}
                height={props.height}
                frameborder={props.frameborder}
                allowfullscreen={props.allowfullscreen}/>
        </div>
    );
}

export default Iframe;