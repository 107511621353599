import React from 'react';


import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/images/SPV_COMP.png'

export default class Navbar extends React.Component{
    constructor(){
        super()
        this.state = {
            visible: false,
            pantalla: window.screen.width
        }
    }
    componentWillMount(){
        this.setState({visible:this.state.pantalla<=768})
    }
    dropDown(){
        if(this.state.pantalla > 768)
            this.setState({visible:!this.state.visible})
    }
    closeDropDown(){
        if(this.state.pantalla > 768)
            this.setState({visible:false})
    }
    render(){
        return (
            <div className='navbar' onMouseLeave={()=>this.closeDropDown()}>
                <figure className='navbar-figure'>
                    <Link to="/">
                        <img src={Logo} alt="soluciones-pv" height="50"/>
                    </Link>
                </figure>
                <FontAwesomeIcon className='burguer-menu' id='burger-menu' icon={faBars} />
                <nav className='navbar-menu'>
                    <ol>
                        <li><Link className="menu-item" id="menu1" to="/">INICIO</Link></li>
                        <li>
                            <div className="menu-item drop-down" onMouseOverCapture={()=>this.dropDown()}>PRODUCTOS Y SOLUCIONES</div>
                            <div className="submenu" style={this.state.visible?{}:{display:'none'}}>
                                <Link className="submenu-item" id="menu20" onClick={()=>this.dropDown()} to="/productos/comer">Alimentación y Comercio</Link>
                                <Link className="submenu-item" id="menu21" onClick={()=>this.dropDown()} to="/productos/indust">Industria</Link>
                                <Link className="submenu-item" id="menu22" onClick={()=>this.dropDown()} to="/productos/insum">Insumos y Consumibles</Link>
                            </div>
                        </li>
                        <li><Link className="menu-item" id="menu3" to="/nosotros">NOSOTROS</Link></li>
                        <li><Link className="menu-item" id="menu4" to="/contacto" >CONTACTO</Link></li>
                    </ol>
                </nav>    
            </div>
        );
    };
}