import React from "react";
import { Link } from "react-router-dom";
import Zoom from 'react-reveal/Zoom';

import Alimentos from "../../assets/images/home/icon_ayc.svg";
import Logistica from "../../assets/images/home/icon_logistic.svg";
import Insumo from "../../assets/images/home/icon_insu.svg";
import Soporte from "../../assets/images/home/icon_consultancy.svg";

const Info = props => {
  const {
    sub_title,
    content,
    categorias,
  } = props.data;
  return (
    <div className="home-info">
      <h2> {sub_title} </h2>
      <p> {content} </p>
      <div className="home-list-category">
        <Zoom>
          <Link to="productos/comer">
            <div className="category">
              <img src={Alimentos} height="200" width="200" alt="alimento" />
              <p> {categorias[0]} </p>
            </div>
          </Link>
        </Zoom>
        <Zoom>
          <Link to="productos/indust">
            <div className="category">
              <img src={Logistica} height="200" width="200" alt="logistica" />
              <p> {categorias[1]} </p>
            </div>
          </Link>
        </Zoom>
        <Zoom>
          <Link to="productos/insum">
            <div className="category">
              <img src={Insumo} height="200" width="200" alt="insumos" />
              <p> {categorias[2]} </p>
            </div>
          </Link>
        </Zoom>
        <Zoom>
          <Link to="/contacto">
            <div className="category">
              <img src={Soporte} height="200" width="200" alt="consultoria" />
              <p> {categorias[3]} </p>
            </div>
          </Link>
        </Zoom>
      </div>
    </div>
  );
};

export default Info;
