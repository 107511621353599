import React from 'react';

import Logo from '../assets/images/SOLUCIONESPV_FOOTER.png'
import PDV from '../../src/assets/pdfs/Politicas_de_Privacidad_Soluciones.pdf'

const Footer = (props) => {
    const year = new Date().getFullYear();
    return (
        <footer className='footer'>
            <div>
                <figure className='footer-figure'>
                    <img src={Logo} alt="soluciones-pv-light" height="50"/>
                    <span>Canal de Tezontle No 169-1 Col. Unidad INFONAVIT</span>
                    <span>Alc. Iztalcalco 08900</span>
                </figure>
            </div>
            <div className='footer-menu'>
                <ol>
                    <li><a className='footer-item' href={PDV} target="_blank" rel="noopener noreferrer">POLITICAS DE PRIVACIDAD</a></li>
                    <li><p className="footer-item">Todos los Derechos Reservados © {year} Soluciones PV</p></li>
                </ol>
            </div>
        </footer>
    );
};

export default Footer;