import React from 'react';
import { Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
 
import Alimentos from '../../assets/images/home/Carrusel/CS-1255.png'
import Industria from '../../assets/images/home/Carrusel/LS-4000.png'
import Insumo from '../../assets/images/home/Carrusel/Insumos.png'
import ImpPV from '../../assets/images/home/Carrusel/epson-tmt88.png'
import EtiSeguridad from '../../assets/images/home/Carrusel/EtiquetasSeguridad.png'
import Plataforma from '../../assets/images/home/Carrusel/Plataformas.png'

const properties = {
  duration: 2500,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}
 
const CarouselCategoryMin = () => {
    return (
      <div className="slide-container">
        <Slide {...properties}>
          <div className="each-slide">
            <Link to="/productos/comer">
              <div style={{'backgroundImage': `url(${Alimentos})`}}>
              </div>
            </Link>
          </div>
          <div className="each-slide">
            <Link to="/productos/insum">
              <div style={{'backgroundImage': `url(${Insumo})`}}>
              </div>
            </Link>
          </div>
          <div className="each-slide">
            <Link to="/productos/indust">
              <div style={{'backgroundImage': `url(${Industria})`}}>
              </div>
            </Link>
          </div>
          <div className="each-slide">
            <Link to="/productos/insum">
              <div style={{'backgroundImage': `url(${EtiSeguridad})`}}>
              </div>
            </Link>
          </div>
          <div className="each-slide">
            <Link to="/productos/indust">
              <div style={{'backgroundImage': `url(${Plataforma})`}}>
              </div>
            </Link>
          </div>
          <div className="each-slide">
            <Link to="/productos/insum">
              <div style={{'backgroundImage': `url(${ImpPV})`}}>
              </div>
            </Link>
          </div>
        </Slide>
      </div>
    )
}
export default CarouselCategoryMin;