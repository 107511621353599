import LS400 from '../../assets/pdfs/industria/LS-400.pdf'
import GW4000 from '../../assets/pdfs/industria/GW-4000+.pdf'
import MD5000 from '../../assets/pdfs/industria/MD5000.pdf'
import DMI600 from '../../assets/pdfs/industria/DMI-600.pdf'
import VD3000 from '../../assets/pdfs/industria/VD-3000.pdf'
import VT800 from '../../assets/pdfs/industria/VT-800.pdf'
import PS5070 from '../../assets/pdfs/industria/Serie-PS.pdf'
import SPC from '../../assets/pdfs/industria/SPC.pdf'
import ZK810 from '../../assets/pdfs/industria/ZK810.pdf'
import ZK830 from '../../assets/pdfs/industria/ZK830.pdf'
import ZK840 from '../../assets/pdfs/industria/ZK840.pdf'

import D900 from '../../assets/pdfs/basculas/D-900-TOUCH.pdf'
import MISTRAL from '../../assets/pdfs/basculas/GAMA-500-MISTRAL.pdf'
import TORNADO from '../../assets/pdfs/basculas/GAMA-500-TORNADO.pdf'
import STAR from '../../assets/pdfs/basculas/GAMA-500-STAR.pdf'
import CS1200 from '../../assets/pdfs/basculas/CS-1200.pdf'
import DPOS400 from '../../assets/pdfs/basculas/DPOS-400.pdf'
import CL3 from '../../assets/pdfs/basculas/CL-3.pdf'

import IMPRESORAS from '../../assets/pdfs/Ficha-Tecnica-Impresoras.pdf'

export const pdf = {
    LS400: LS400,
    GW4000: GW4000,
    MD5000: MD5000, 
    DMI600: DMI600,
    VD3000: VD3000,
    VT800: VT800,
    PS5070: PS5070,
    PC50: SPC,
    ZK810: ZK810,
    ZK830: ZK830,
    ZK840: ZK840,
    etiqueta: null,
    rollos: null,
    ribbons: null,
    IMPRESORAS: IMPRESORAS,
    otros: null,
    D900: D900,
    MISTRAL: MISTRAL,
    TORNADO: TORNADO,
    STAR,
    CL3: CL3,
    CS1200: CS1200,
    DPOS400: DPOS400
}