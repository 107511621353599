import React from 'react';
import Slide from 'react-reveal/Slide';

import ScrollButton from '../components/extras/ScrollButton'
import SomosPV from '../components/nosotros/SomosPV'
import SomosPVNum from '../components/nosotros/SomosPVNum'
import Normas from '../components/nosotros/Normas'
import Clientes from '../components/nosotros/Clientes'
import data from '../assets/data/nosotros.json' 

const nosotros = (props) => {
    return (
        <div>
            <Slide bottom><SomosPV data={data.somos}/></Slide>
            <Slide bottom><SomosPVNum data={data.numeros}/></Slide>
            <Slide bottom><Normas data={data.numeros}/></Slide>
            <Slide bottom><Clientes data={data.clientes}/></Slide>
            <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
        </div>
    );
};

export default nosotros;