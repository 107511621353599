import React from 'react';

const HeroCategory = (props) => {
    return (
        <div className='hero-category-insumos'>
            <div className='insumo-hero-title'> {props.data.titles[1]} </div>
        </div>
    );
};

export default HeroCategory;