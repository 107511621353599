import React from "react";
import {Link} from 'react-router-dom';

import Button from "../extras/Button";
import CS1200 from "../../assets/images/productos/AlimentacionComercio/CS-1200.png"
import MISTRAL from "../../assets/images/productos/AlimentacionComercio/MISTRAL.png"
import TORNADO from "../../assets/images/productos/AlimentacionComercio/TORNADO.png"
import CL3 from "../../assets/images/productos/AlimentacionComercio/CL-3.png"
import STAR from "../../assets/images/productos/AlimentacionComercio/STAR.png"
import D900 from "../../assets/images/productos/AlimentacionComercio/D-900.png"
import DPOS400 from "../../assets/images/productos/AlimentacionComercio/DPOS-400.png"

const AlimentosItem = props => {
  const {category,product} = props

  if(category === 0){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={DPOS400} alt="DPOS-400"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 1){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={MISTRAL} alt="Mistral"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container">
          <img src={TORNADO} alt="Tornado"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container">
          <img src={STAR} alt="STAR"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 2){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={D900} alt="D-900 Touch"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container">
          <img src={CS1200} alt="CS 1200"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container">
          <img src={CL3} alt="CL-3"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  
  return (
    <div className="item-container">
      
    </div> 
    )
};

export default AlimentosItem;
