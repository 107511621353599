import React from 'react';

import Normativas from '../../assets/images/nosotros/logo_nom_nyce.png'

const Normas = (props) => {
    return (
        <div className='Normas-container'>
            <img src={Normativas} alt="normativas" height='120px'/>
            <p> {props.data.nom} </p>
        </div>
    );
};

export default Normas;