import React from 'react';
import Zoom from 'react-reveal/Zoom';

import Garis from '../../assets/images/nosotros/logo_garis.png'
import Google from '../../assets/images/nosotros/logo_google.png'
import Scorpion from '../../assets/images/nosotros/logo_scorpion.png'
import DelFruto from '../../assets/images/nosotros/logo_delfruto.png'
import Qualtia from '../../assets/images/nosotros/logo_qualtia.png'

const Clientes = (props) => {
    return (
        <div className='nosotros-clientes-container'>
            <h1> {props.data} </h1>
            <dir className='nosotros-clientes-list'>
                <Zoom><img src={Garis} alt="Garis" height="100" className="nosotros-clientes-item"/></Zoom>
                <Zoom><img src={Google} alt="Google" height="100" className="nosotros-clientes-item"/></Zoom>
                <Zoom><img src={Scorpion} alt="Scorpion" height="100" className="nosotros-clientes-item"/></Zoom>
                <Zoom><img src={DelFruto} alt="delfruto" height="100" className="nosotros-clientes-item"/></Zoom>
                <Zoom><img src={Qualtia} alt="Qualtia" height="100" className="nosotros-clientes-item"/></Zoom>
            </dir>
        </div>
    );
};

export default Clientes;