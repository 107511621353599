import React from 'react';
import Slide from 'react-reveal/Slide';

import Asesoria from '../components/contacto/Asesoria.js'
import Contactanos from '../components/contacto/Contactanos'
import Iframe from '../components/contacto/Iframe'
import ScrollButton from '../components/extras/ScrollButton'
import data from '../assets/data/contactanos.json' 

const contacto = (props) => {
    return (
        <div className='contacto-container'>
            <div className='contacto-data'>
                <Slide left><Asesoria data={data.formularios}/></Slide>
                <Slide right><Contactanos data={data.contactanos}/></Slide>
            </div>
            <Iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.9022532653184!2d-99.10285847081555!3d19.38607519918195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff2c1b0c0c67%3A0x8499fa2f0e87577f!2sSOLUCIONES%20PV!5e0!3m2!1ses-419!2smx!4v1581614602541!5m2!1ses-419!2smx' width="400" height="300" frameborder="0" style={{border:0}} allowfullscreen=""/>
            <ScrollButton/>
        </div>
    );
};

export default contacto;