import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const Button = props => {
  if (props.size === 'lg') {
    return <div className="button-more-info-lg">{props.title}</div>;
  }
  if(props.mode === "whatsapp"){
    return( 
      <div className="button-more-info-whatsapp">
        <FontAwesomeIcon icon={faWhatsapp} size="" /> {props.title} 
      </div>
    );
  }
  if(props.size === "xs"){
    return( 
      <div className="button-more-info-xs">
        {props.title} 
      </div>
    );
  }
  return <div className="button-more-info-s">{props.title}</div>;
};

export default Button;
