import React from 'react';

const HeroCategory = (props) => {
    return (
        <div className='hero-category-industria'>
            <div className='insumo-hero-title'> {props.data.titles[0]} </div>
        </div>
    );
};

export default HeroCategory;