import React from 'react';

import Notfound from '../../assets/images/404.png' 

const NotFound = (props) => {
    return (
        <div className='not-found'>
            <img src={Notfound} alt="Not-found" height="350"/>
            <h1>Pagina no encontrada</h1>
            <p>¡Vaya!, parece que lo que estas buscando no ha sido encontrado </p>
        </div>
    );
};

export default NotFound;