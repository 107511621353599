import React from "react";
import Button from "../extras/Button";

export default class Asesoria extends React.Component {
    constructor(){
        super()
        this.state = {
            visibleMessage : false,
            title : '',
            description: []
        }
    }
    componentWillMount(){
        this.setState({title:this.props.data.title,description:this.props.data.description})
    }
    setInfo(){
        let nombre = document.getElementById("nombre").value
        let compania = document.getElementById("compania").value
        let email = document.getElementById("email").value
        let phone = document.getElementById("phone").value
        let topic = document.getElementById("topic").value
        this.setState({visibleMessage:false})
        let url = 'https://script.google.com/macros/s/AKfycbxv0enBlOLMxPdYCs_sfrvEFYkvry0103K0OzwIxfIDZRzbJAUN/exec?nombre='+nombre+'&compania='+compania+'&telefono='+phone+'&email='+email+'&topic='+topic+'&status=PENDING'
        const http = new XMLHttpRequest()
        http.open("GET", url, true)
        http.onreadystatechange = () => {
            if(this.readyState === 4 && this.status === 200){
                var resultado = JSON.parse(this.responseText)
                console.log(resultado)
            }
        }
        this.borrarInfo()
        this.setState({visibleMessage:true})
        http.send()
      }
    borrarInfo(){
        document.getElementById("nombre").value = ""
        document.getElementById("compania").value = ""
        document.getElementById("email").value = ""
        document.getElementById("phone").value = ""
        document.getElementById("topic").value = ""
      }
    render(){
        return(
            <div className="contacto-asesorias-container">
                <h2> {this.state.title} </h2>
                <p> {this.state.description[0]} </p>
                <p> {this.state.description[1]} </p>
                <div className="contacto-asesorias-container-form">
                    <input type="text" id="nombre" placeholder="Nombre completo" required/>
                    <input type="text" id="compania" placeholder="Compañia" required/>
                    <input type="text" id="phone" placeholder="Número de teléfono" required/>
                    <input type="email" id="email" placeholder="Correo electrónico" required/>
                    <textarea id="topic" maxLength="300" placeholder="Datos generales de producto de tu interés" required></textarea>
                    <div onClick={() => this.setInfo()}>
                        <Button title="ENVIAR"/>
                    </div>
                    <div style={this.state.visibleMessage?{}:{display:'none'}}>
                        <h3>¡Gracias por contactar con nosotros!</h3>
                    </div>
                </div>
            </div>
        )
    }
}