import React from "react";

import Button from "../extras/Button";
import Location from "../../assets/images/contacto/icon_location.svg";
import Phone from "../../assets/images/contacto/icon_phone.svg";
import Time from "../../assets/images/contacto/icon_horario.svg";
import Email from "../../assets/images/contacto/icon_email.svg";
import Web from "../../assets/images/contacto/icon_web.svg";
import Fb from "../../assets/images/contacto/icon_fb.svg";

export default class Contactanos extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      title: null,
      title2: null,
      dirreccion: null,
      tel: null,
      dias: null,
      hora: null,
      email: null,
      web: null,
      fb: null,
      whatsappURIweb: 'https://web.whatsapp.com/send?phone=',
      whatsappURImovil: 'https://api.whatsapp.com/send?phone=',
      URIwhatsapp: null,
      number: '5215580547921',
      message: 'Hola%20estoy%20interesado%20en%20sus%20servicios'
    }
  }
  componentWillMount (){
    this.setState({
      title: this.props.data.title,
      title2: this.props.data.title2,
      dirreccion: this.props.data.dirreccion,
      tel: this.props.data.tel,
      dias: this.props.data.dias,
      hora: this.props.data.hora,
      email: this.props.data.email,
      web: this.props.data.web,
      fb: this.props.data.fb
    })
    let URI = null
    if(
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i))
    ){
      URI = this.state.whatsappURImovil
    }
    else
      URI = this.state.whatsappURIweb
    URI = URI + this.state.number + "&amp;text=" + this.state.message
    this.setState({URIwhatsapp:URI})
  }
  render(){
    return (
      <div className="contacto-contactanos-container">
        <h2> {this.state.title} </h2>
        <h2 className="buttom-h2"> {this.state.title2} </h2>
        <div className="contactanos-medio">
          <img src={Location} alt="ubicación" width="30" height="30" />
          <div className="p-items">
            <p> {this.state.dirreccion.avenida} </p>
            <p>
              {" "}
              {this.state.dirreccion.colonia} {this.state.dirreccion.cp}{" "}
            </p>
            <p> {this.state.dirreccion.delegacion} </p>
          </div>
        </div>
        <div className="contactanos-medio">
          <img src={Phone} alt="telefono" width="30" height="30" />
          <p> {this.state.tel} </p>
        </div>
        <div className="contactanos-medio">
          <img src={Time} alt="dias/horas" width="30" height="30" />
          <div className="p-items">
            <p> {this.state.dias} </p>
            <p> {this.state.hora} </p>
          </div>
        </div>
        <div className="contactanos-medio">
          <img src={Email} alt="correo electrónico" width="30" height="30" />
          <p> {this.state.email} </p>
        </div>
        <div className="contactanos-medio">
          <img src={Web} alt="web" width="30" height="30" />
          <p> <a href="http://www.solucionespv.mx" target="_blank" rel="noopener noreferrer">{this.state.web}</a> </p>
        </div>
        <div className="contactanos-medio">
          <img src={Fb} alt="facebook" width="30" height="30" />
          <p> <a href="https://www.facebook.com/SolucionesMXPV/" target="_blank" rel="noopener noreferrer">{this.state.fb}</a> </p>
        </div>
        <a className="btn-category" target="_blanck" rel="noopener noreferrer" href={this.state.URIwhatsapp}>
          <Button title="ATENCIÓN EN LINEA"  mode="whatsapp"/>
        </a>
      </div>
    );
  };
}
