import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'

const InfoHacemos = props => {
  const {
    title,
    item1,
    item2,
    item3,
  } = props.data;
  return (
    <div className='home-hacemos'>
      <div className="home-hacemos-list">
          <h2> {title} </h2>
        <div className="hacemos-category-grid">
          <div className='hacemos-category-item'> <FontAwesomeIcon icon={faDotCircle} color="#a00202"/> {item1} </div>
          <div className='hacemos-category-item'> <FontAwesomeIcon icon={faDotCircle} color="#a00202"/> {item2} </div>
          <div className='hacemos-category-item'> <FontAwesomeIcon icon={faDotCircle} color="#a00202"/> {item3} </div>
        </div>
      </div>
    </div>
  );
};

export default InfoHacemos;
