import React from 'react';
import Slide from 'react-reveal/Slide';

import Mision from '../../assets/images/nosotros/icon_mision.svg'
import Valores from '../../assets/images/nosotros/icon_valores.svg'
import Vision from '../../assets/images/nosotros/icon_vision.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'

const SomosPV = (props) => {
    const {title, title2, title3, description, item1, item2, item3} = props.data
    return (
        <div className='nosotros-somospv-container'>
            <h1> {title.toUpperCase()} </h1>
            <h1 className='margin-fix'> <span> {title2.toUpperCase()} </span> {title3.toUpperCase()} </h1>
            <p> {description} </p>
            <div className='somospv-list'>
                <Slide bottom>
                    <div className="somos-pv-list-item">
                        <img src={Mision} alt="mision" height='160' width='160'/>
                        <h2> {item1.title} </h2>
                        <p> {item1.description} </p>
                    </div>
                </Slide>
                <Slide bottom>
                    <div className="somos-pv-list-item">
                        <img src={Vision} alt="vision" height='160' width='160'/>
                        <h2> {item2.title} </h2>
                        <p> {item2.description} </p>
                    </div>
                </Slide>
                <Slide bottom>
                    <div className="somos-pv-list-item">
                        <img src={Valores} alt="valores" height='160' width='160'/>
                        <h2> {item3.title} </h2>
                        <ol>
                            {item3.list.map(item =>{
                                return <li key={item.toString()}> <FontAwesomeIcon icon={faDotCircle} color="#a00202"/> {item} </li>
                            })}
                        </ol>
                    </div>
                </Slide>
            </div>
        </div>
    );
};

export default SomosPV;