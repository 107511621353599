import React from 'react';

import HeroCategory from './productos/HeroCategoryComercial'
import AlimentosCategory from './productos/AlimentosCategory'
import ScrollButton from '../components/extras/ScrollButton'
import data from '../assets/data/productos.json' 

const ProductoAlimento = (props) => {
    return (
        <div>
            <HeroCategory data={data}/>
            <AlimentosCategory data={data.item2}/>
            <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
        </div>
    );
};

export default ProductoAlimento;