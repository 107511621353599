import React from 'react';
import ReactDOM from 'react-dom';

import ModalBody from './ModalBody'

export default class Modal extends React.Component{
  hide(){
    this.props.hide(false)
  }
  render(){
    return(
      this.props.isShowing ? ReactDOM.createPortal(
        <React.Fragment>
        <div className="modal-overlay"/>
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className="modal">
            <div className="modal-header">
              <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={()=>this.hide()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <ModalBody info={this.props.info}/>
          </div>
        </div>
      </React.Fragment>, document.body
      ):null
    )
  }
}