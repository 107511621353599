import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import '../src/components/styles/global.css'
import '../src/components/styles/home.css'
import '../src/components/styles/nosotros.css'
import '../src/components/styles/contacto.css'
import '../src/components/styles/productos.css'

// Compatiblidad con IE9 e IE11
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render(<App />, document.getElementById('app'));
