import InsumoItem from './InsumoItem';
import React from 'react';
import Zoom from 'react-reveal/Zoom';
import Modal from "../extras/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'

class InsumoCategory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: null,
            description: null,
            subTitle: null,
            category: null,
            isShown: false,
            categoryInfo: 0,
            productInfo: 0,
            item: "",
            table: [true],
            isShowing: false,
            activeRow: 0
        }
    }

    toggle(state){
        this.setState({isShowing:state})
    }

    componentWillMount(){
        this.setState({
            name:this.props.data.name,
            description:this.props.data.description,
            subTitle:this.props.data.subTitulo,
            category:this.props.data.category
        })
    }

    componentDidMount(){
        let producto = queryString.parse(this.props.location.search).p
        let category = queryString.parse(this.props.location.search).c
        if(producto != null && category != null){
            try{
                if(this.state.category[category].products[producto] != null){
                    this.handlerOnClick(parseInt(category),parseInt(producto))
                }
            }
            catch{
                console.log("El elemento cateroria: " + category + " y producto: " + producto + " no existe")
            }
        }
        else if(this.props.match.params.prod != null && this.props.match.params.categ){
            try{
                if(this.state.category[this.props.match.params.categ].products[this.props.match.params.prod] != null){
                    this.handlerOnClick(parseInt(this.props.match.params.categ),parseInt(this.props.match.params.prod))
                }
            }
            catch{
                console.log("El elemento cateroria: " + this.props.match.params.categ + " y producto: " + this.props.match.params.prod + " no existe")
            }
        }
    }

    handlerOnClick(index,counter){
        this.setState({isShowing:true})
        this.setState({categoryInfo:index,productInfo:counter})
    }

    handlerHover(index,counter){
        this.setState({categoryInfo:index,productInfo:counter})
    }

    handleTable(index){
        let tmp = Object.assign([], this.state.table)
        if(this.state.activeRow !== index)
            tmp[this.state.activeRow] = false
        tmp[index] = !this.state.table[index]
        this.setState({table:tmp})
        this.setState({activeRow:index})
    }

    constructSubCategory(index){
        let categoryItem = this.state.category[index]
        let content = []
        let products = []
        if(categoryItem.name !== "")
            content.push(<h3 onClick={() => this.handleTable(index)}><FontAwesomeIcon icon={faDotCircle} color="#a00202" style={{marginRight:10}} />{categoryItem.name}</h3>)
        if(categoryItem.description != null){
            categoryItem.description.forEach(element => {
                products.push(<p>{element}</p>)
            });
        }
        for (let count = 0; count < categoryItem.products.length; count++) {
            let tmp = categoryItem.products[count]
            products.push(
                <Zoom>
                    <div className="category-item" tabIndex={count} onClick={() => this.handlerOnClick(index,count)} onMouseOver={() => this.handlerHover(index,count)}> {tmp.name} </div>
                </Zoom>
            )
        }
        let productsList = (<div className='category-list' style={{display: this.state.table[index] ? 'flex' : 'none'}}>{products}</div>)
        content.push(productsList)
        return (<div className="category-subsection">{content}</div>)
    }

    fillSubCategories(){
        let subCategories = []
        for (let i = 0; i < this.state.category.length; i++) {
            subCategories.push(
                    this.constructSubCategory(i)
            )
        }
        return subCategories
    }

    fillDescription(){
        let tmp = []
        this.state.description.forEach(element => {
            tmp.push(<p>{element}</p>)
        });
        tmp.push(<h4>{this.state.subTitle}</h4>)
        return tmp
    }

    render(){
        return (
        <div className='category-container'>
            <h2> {this.state.name} </h2>
            {this.fillDescription()}
            <div className="category-interact-container">
                <div className="category-subsection-container">
                    {this.fillSubCategories(0)}
                </div>
                <InsumoItem category={this.state.categoryInfo} product={this.state.productInfo}/>
            </div>
            <Modal
                isShowing={this.state.isShowing}
                hide={this.toggle.bind(this)}
                info = {this.state.category[this.state.categoryInfo].products[this.state.productInfo]}
            />
        </div>
        )
    }
};

export default withRouter(InsumoCategory)