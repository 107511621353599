import React from 'react';
import Zoom from 'react-reveal/Zoom';

const SomosPVNum = (props) => {
    const {title, title2, title3, item1, item2, item3, item4, item5, item6, item7, item8} = props.data
    return (
        <div className='somospv-numeros-container'>
            <h1> {title.toUpperCase()} <span> {title2.toUpperCase()} </span> </h1>
            <h1 className='margin-fix'> {title3} </h1>
            <div className='somospv-numeros-list'>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item1.number} </h1>
                        <p> {item1.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item2.number} </h1>
                        <p> {item2.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item3.number} </h1>
                        <p> {item3.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item4.number} </h1>
                        <p> {item4.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item5.number} </h1>
                        <p> {item5.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item6.number} </h1>
                        <p> {item6.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item7.number} </h1>
                        <p> {item7.description} </p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='somospv-numeros-list-items'>
                        <h1> {item8.number} </h1>
                        <p> {item8.description} </p>
                    </div>
                </Zoom>
            </div>
        </div>
    );
};

export default SomosPVNum;