import React from 'react';
import { Link } from 'react-router-dom'

import Button from "../extras/Button";
import {pdf} from '../../assets/data/pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'

const ModalBody = (props) => {
    function fillDescription(){
        let description = []
        props.info.description.forEach(element => {
            description.push(<p>{element}</p>)
        });
        return description
    }
    function putDescription(elem){
        let tmp = []
        tmp.push(elem.item)
        if(elem.description != null){
            tmp.push(<p className="list-description"> -> {elem.description}</p>)
        }
        return tmp
    }
    function fillType(){
        let type = []
        if(props.info.type != null){
            props.info.type.forEach(element => {
                type.push(
                    <li key={element.toString()}><FontAwesomeIcon icon={faDotCircle} color="#a00202" style={{marginRight:10}} />{putDescription(element)} </li>
                )
            });
        }
        return type
    }
    function putPDFButton(){
        if(props.info.PDF != null)
            return (<a className="btn-category" href={pdf[props.info.PDF]} target="_blank" rel="noopener noreferrer"><Button title="VER PDF" size='xs' /></a>)
    }
    return (
        <div className='modal-body-container'>
            <h2> {props.info.name} </h2>
            {fillDescription()}
            <ol>
                {fillType()}
            </ol>
            <div className="modal-button-container">
                <Link className='btn-category' to="/contacto">
                    <Button title="MÁS INFORMACIÓN" size='xs' />
                </Link>
                {putPDFButton()}
            </div>
        </div>
    )
};

export default ModalBody;