import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./Home";
import ProductoInsumo from "./ProductoInsumo";
import ProductoIndustria from "./ProductoIndustria";
import ProductoAlimento from "./ProductoAlimento";
import Nosotros from "./Nosotros";
import Contacto from "./Contacto";
import NotFound from "./extras/NotFound";
// import Building from './extras/Building'
import Layout from "./Layout";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact stric path="/productos/insum" component={ProductoInsumo} />
          <Route exact stric path="/productos/indust" component={ProductoIndustria} />
          <Route exact stric path="/productos/comer" component={ProductoAlimento} />
          {/**
           * Las siguientes son las rutas específicas de los productos
           */}
          <Route path="/productos/insum/:categ/:prod" component={ProductoInsumo} />
          <Route path="/productos/indust/:categ/:prod" component={ProductoIndustria} />
          <Route path="/productos/comer/:categ/:prod" component={ProductoAlimento} />
          {/**
           * Aqui terminan las rutas específicas
           */}
          <Route exact stric path="/nosotros" component={Nosotros} />
          <Route exact stric path="/contacto" component={Contacto} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
