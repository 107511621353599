import React from "react";

const Slogan = props => {
  const {
    title,
  } = props.data;
  return (
    <div className="home-slogan">
      <h1> {title} </h1>
    </div>
  );
};

export default Slogan;
