import React from 'react';
import { Element } from 'react-scroll'

import Fade from 'react-reveal/Fade';
import Hero from '../components/home/Hero'
import Info from '../components/home/Info'
import Slogan from '../components/home/Slogan'
import InfoHacemos from '../components/home/InfoHacemos'
import CategoriaSoporte from '../components/home/CategoriaSoporte'
import ScrollButton from '../components/extras/ScrollButton'
import Envio from '../components/home/Envio'
import data from '../assets/data/home.json' 

const home = (props) => {
    return (
        <div className='container'>
            <Hero data={data.hero}/>
            <Fade bottom><Slogan data={data.info}/></Fade>
            <Fade bottom><InfoHacemos data={data.hacemos}/></Fade>
            <Fade bottom><Info data={data.info}/></Fade>
            <Element name="sectionproductos" className="ancla"> </Element>
            <Fade left><CategoriaSoporte data={data.categoria_4}/></Fade>
            <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
            <Envio data={data.envios}/>
        </div>
    );
};

export default home;