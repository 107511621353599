import React from 'react';

import HeroCategory from './productos/HeroCategoryIndustria'
import IndustriaCategory from './productos/IndustriaCategory'
import ScrollButton from '../components/extras/ScrollButton'
import data from '../assets/data/productos.json' 

const ProductoIndustria = (props) => {
    return (
        <div>
            <HeroCategory data={data}/>
            <IndustriaCategory data={data.item3}/> 
            <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
        </div>
    );
};

export default ProductoIndustria;