import React from "react";
import {Link} from 'react-router-dom';

import Button from "../extras/Button";
import Etiquetas from "../../assets/images/productos/InsumosConsumibles/EtiquetasPersonalizas.png"
import Rollos from "../../assets/images/productos/InsumosConsumibles/Rollos.png"
import Ribbons from "../../assets/images/productos/InsumosConsumibles/Ribbons.png"
import Impresoras from "../../assets/images/productos/InsumosConsumibles/Zebra-ZT410.png"
import Otros from "../../assets/images/productos/InsumosConsumibles/Cartucheras.png"

const InsumoItem = props => {
  const {category,product} = props

  if(category === 0){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={Etiquetas} alt="etiquetas"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container">
          <img src={Rollos} alt="rollos"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container">
          <img src={Ribbons} alt="ribbons"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 3) {
      return (
        <div className="item-container">
         <img src={Impresoras} alt="impresoras"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 4) {
      return (
        <div className="item-container">
          <img src={Otros} alt="diversos consumibles"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  return (
    <div className="item-container">
      
    </div> 
    )
};

export default InsumoItem;
