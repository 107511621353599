import React from "react";
import {Link} from 'react-router-dom';

import Button from "../extras/Button";
import LS4000 from "../../assets/images/productos/Industria/LS-4000.png"
import GW4000 from "../../assets/images/productos/Industria/GW-4000+.png"
import MD5500 from "../../assets/images/productos/Industria/MD-5500.png"
import MD5700 from "../../assets/images/productos/Industria/MD-5700.png"
import MDS5700 from "../../assets/images/productos/Industria/MDS-5700.png"
import DMI620 from "../../assets/images/productos/Industria/DMI-620.png"
import VD3000 from "../../assets/images/productos/Industria/VD-3000.png"
import VT800 from "../../assets/images/productos/Industria/VT-800.png"
import M420 from "../../assets/images/productos/Industria/M420.png"
import MA1 from "../../assets/images/productos/Industria/MA1.png"
import PS70 from "../../assets/images/productos/Industria/PS-7011.png"
import PC50 from "../../assets/images/productos/Industria/PC-50.png"
import ZK810 from "../../assets/images/productos/Industria/ZK810.png"
import ZK830 from "../../assets/images/productos/Industria/ZK830.png"
import ZK840 from "../../assets/images/productos/Industria/ZK840.png"

const IndustriaItem = props => {
const {category,product} = props

  if(category === 0){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={LS4000} alt="LS-4000"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 1){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={GW4000} alt="GW-4000+"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 2){
    if ( product === 0) {
      return (
        <div className="item-container">
          <img src={MD5500} alt="MD-5500"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container">
         <img src={MD5700} alt="MD-5700"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container">
         <img src={MDS5700} alt="MDS-5700"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 3){
    if ( product === 0) {
      return (
        <div className="item-container fix-margin">
         <img src={DMI620} alt="DMI-620"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container fix-margin">
         <img src={VD3000} alt="VD-3000"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container fix-margin">
         <img src={VT800} alt="VT-800"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 4){
    if ( product === 0) {
      return (
        <div className="item-container fix-margin">
         <img src={PS70} alt="PS 50 / 70"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 1) {
      return (
        <div className="item-container fix-margin">
         <img src={PC50} alt="PC 50 / SPC"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 2) {
      return (
        <div className="item-container fix-margin">
         <img src={ZK810} alt="ZK810"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 3) {
      return (
        <div className="item-container fix-margin">
         <img src={ZK830} alt="ZK830"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
    if ( product === 4) {
      return (
        <div className="item-container fix-margin">
         <img src={ZK840} alt="ZK840"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 5){
    if ( product === 0) {
      return (
        <div className="item-container fix-margin">
         <img src={M420} alt="M420"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  if(category === 6){
    if ( product === 0) {
      return (
        <div className="item-container fix-margin">
         <img src={MA1} alt="MA1"/>
          <Link className="btn-category" to="/contacto">
            <Button title="MÁS INFORMACIÓN"  size='xs'/>
          </Link>
        </div>
      );
    }
  }
  return (
    <div className="item-container">
      
    </div> 
    )
};

export default IndustriaItem;
